import React, { FC } from 'react';

import { Box, Button } from '@material-ui/core';

import CartUniqueItemsCount from 'ui/common/icons/CartUniqueItemsCount';
import Typography from 'ui/common/Typography';

import { useRenderPrice } from 'hooks';

import { useSelector } from 'react-redux';
import { getLang } from 'store/modules/config/selectors';
import useStyles from './styles';

import { IGoToCart } from './types';

const GoToCart: FC<IGoToCart> = ({ label, price, onClick, count, hideBasketIcon, ...rest }) => {
  const classes = useStyles();

  const renderPrice = useRenderPrice();

  const lang = useSelector(getLang);

  return (
    <Button
      classes={{ root: classes.rootButton, label: classes.label }}
      variant="contained"
      color="primary"
      onClick={onClick}
      fullWidth
      {...rest}
    >
      <Box display="flex" flex={1} justifyContent="center" alignItems="center">
        {!hideBasketIcon && (
          <CartUniqueItemsCount
            color="secondary"
            iconFontSize="default"
            textFontSize={10}
            className={classes.basket}
            iconClasses={{ root: classes.basketIcon }}
            count={count}
          />
        )}
        <Typography
          className="white-space-no-wrap text-transform-initial"
          color="light"
          variant="body1"
          fontWeight="medium"
        >
          {label}
        </Typography>
      </Box>

      <Box className={classes.price}>
        <Typography
          color="light"
          variant="body2"
          fontWeight={lang === 'ru' ? 'semiBoldRoboto' : 'semiBold'}
        >
          {renderPrice(price)}
        </Typography>
      </Box>
    </Button>
  );
};

export default GoToCart;
