import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

import { TStylesGoToCart } from './types';

const downBreakpoint: Breakpoint = 'sm';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesGoToCart> =>
    createStyles({
      price: {
        backgroundColor: theme.palette.primary.dark,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: theme.spacing(14.375), // 115px
        padding: theme.spacing(2),
      },
      label: {
        height: '100%',
      },
      rootButton: {
        borderRadius: theme.spacing(12.5), // 100
        padding: 0,
        height: theme.spacing(5.75), // 46px
        display: 'flex',
        justifyContent: 'space-between',
        overflow: 'hidden',
        boxShadow: 'none',

        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          boxShadow: 'none',
        },

        '&:active': {
          backgroundColor: theme.palette.primary.dark,
          boxShadow: 'none',
        },

        '&:disabled': {
          backgroundColor: theme.palette.primary.main,
          opacity: 0.4,

          [theme.breakpoints.down(downBreakpoint)]: {
            opacity: 0.3,
          },
        },
      },
      basket: {
        marginRight: theme.spacing(0.8125), // 6.5px
      },
      basketIcon: {
        fontSize: theme.spacing(3.5), // '28px'
      },
    }),
);
