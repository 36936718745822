import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesUsePromotions } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesUsePromotions, { lang: string }> =>
    createStyles({
      promotionProgressLabel: ({ lang }) => ({
        fontSize: lang === 'ru' ? '13px' : '14px',
        lineHeight: lang === 'ru' ? '15px' : '18px',
        textAlign: 'left',
        color: theme.palette.secondary.main,
        fontWeight: 500,
        display: 'inline',
      }),
      questionIcon: {
        fontSize: '14px',
        marginLeft: '6px',
      },
      tooltip: {
        width: 'auto',
        maxWidth: '80vw',
        padding: theme.spacing(1),
        backgroundColor: `${theme.palette.secondary.main}!important`,
        top: '-14px !important',
        left: '4px !important',
        [theme.breakpoints.up('md')]: {
          maxWidth: 'max-content',
          top: '-5px !important',
        },
      },
    }),
);
