import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesProgressBarWithLabel } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesProgressBarWithLabel, { isMobile: boolean }> =>
    createStyles({
      wrapper: {
        backgroundColor: theme.palette.grey[200],
        borderRadius: '4px',
        minHeight: '48px',
        padding: '8px 10px 10px',
      },
      closeButton: { padding: 0, marginLeft: '8px' },
      closeIcon: {
        color: theme.palette.primary.main,
        fontSize: '10px',
      },
      progressBarRoot: {
        borderRadius: '2px',
        backgroundColor: '#e4e8e8',
      },
      label: ({ isMobile }) => ({
        maxWidth: isMobile ? 'auto' : '262px',
      }),
    }),
);
