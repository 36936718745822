import React, { FC } from 'react';

import { Box } from '@material-ui/core';
import Typography from 'ui/common/Typography';
import CTAButton from 'ui/common/buttons/CTAButton';

import { IRemovedCartItemSection } from './types';
import useStyles from './styles';

const RemovedCartItemSection: FC<IRemovedCartItemSection> = ({
  onKeepItemInCart,
  onDeleteItem,
  storeProductId,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.deleteBlockSection}>
      <Box className={classes.deleteBlock}>
        <Typography className={classes.removedItemTitle}>{'topCard.removedItem'}</Typography>
        <Box className={classes.deleteBlockButtons}>
          <CTAButton
            classes={{ root: classes.restoredButton }}
            onClick={onKeepItemInCart}
            size="small"
          >
            {'topCard.restore'}
          </CTAButton>
          <CTAButton
            classes={{ root: classes.deleteButton }}
            variant="outlined"
            onClick={() => onDeleteItem(storeProductId)}
            size="small"
          >
            {'topCard.delete'}
          </CTAButton>
        </Box>
      </Box>
    </Box>
  );
};

export default RemovedCartItemSection;
