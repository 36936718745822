import { createStyles, makeStyles, StyleRules } from '@material-ui/core';

import { TStylesContinueToPaymentFixed } from './types';

export default makeStyles(
  (theme): StyleRules<TStylesContinueToPaymentFixed> =>
    createStyles({
      buttonWrp: {
        width: '100%',
        height: '88px',
        position: 'sticky',
        bottom: 0,
        backgroundColor: theme.palette.grey['50'],
        padding: '14px 14px 0px 14px',
        boxShadow: '0 -2px 7px 0 rgba(0, 0, 0, 0.1)',
      },
      revaluationNotice: {
        paddingTop: '5px',
      },
    }),
);
