import { BaseApi } from 'api/Api';
import { TGetOrderDrafts, TPostOrderDraft } from './types';

export default class OrderDraftsService {
  static postOrderDraft: TPostOrderDraft = async (params, config) => {
    return BaseApi.instance({ handleError: false, ...config }).post(
      `/client/client/orders/drafts`,
      params,
    );
  };

  static getOrderDraft: TGetOrderDrafts = async () => {
    return BaseApi.instance().get(`/client/client/orders/drafts`);
  };
}
