import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesRemovedCartItemSection } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesRemovedCartItemSection> =>
    createStyles({
      deleteBlockSection: {
        position: 'absolute',
        height: '100px',
        display: 'flex',
        alignItems: 'center',
        left: 0,
        right: 0,
        top: 0,
        zIndex: 2,
        [theme.breakpoints.up('md')]: {
          '&:hover $deleteBlockButtons': {
            display: 'flex',
          },
          '&:hover $removedItemTitle': {
            display: 'none',
          },
        },
      },
      deleteBlock: {
        margin: '0 auto',
      },
      deleteBlockButtons: {
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
      removedItemTitle: {
        textAlign: 'center',
        fontSize: theme.spacing(2),
        color: theme.palette.text.secondary,
        fontWeight: 500,
        marginBottom: theme.spacing(5 / 8),
      },
      restoredButton: {
        width: theme.spacing(84 / 8),
        height: theme.spacing(34 / 8),
        fontSize: '16px',
        boxShadow: 'none',
        minWidth: 'initial',
        margin: theme.spacing(7 / 8),
        [theme.breakpoints.up('md')]: {
          margin: theme.spacing(6 / 8),
        },
      },
      deleteButton: {
        width: theme.spacing(84 / 8),
        height: theme.spacing(34 / 8),
        color: theme.palette.grey.A700,
        fontSize: '16px',
        fontWeight: 'normal',
        minWidth: 'initial',
        margin: theme.spacing(7 / 8),
        [theme.breakpoints.up('md')]: {
          margin: theme.spacing(6 / 8),
        },
      },
    }),
);
