import { Box, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import ProgressBarWithLabel from 'components/common/ProgressBarWithLabel';
import { useCart } from 'hooks/useCart';
import { useMultiLanguage } from 'hooks/useMultiLanguage';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  authSelectors,
  IPromotion,
  prepareToPlaceOrderSelectors,
  promotionsSelectors,
  shoppingFlowSelectors,
} from 'store';
import { getLang } from 'store/modules/config/selectors';
import { getOrderDetails } from 'store/modules/orderDetails/selectors';
import { getOrderDiscounts } from 'store/modules/orderDiscounts/selectors';
import Icon from 'ui/common/icons/Icon';
import TooltipOnIcon from 'ui/common/TooltipOnIcon';
import Typography from 'ui/common/Typography';
import { convertNumberToWholeOrTwoDecimal } from 'utils/helpers/collection';
import {
  validateOrderType,
  validatePromotionRelevantWeekday,
} from 'utils/helpers/promotions/validators';
import useStyles from './styles';
import {
  TPrimaryQuantityUnitText,
  TPromotionIconName,
  TPromotionText,
  TUsePromotions,
} from './types';

const usePromotions: TUsePromotions = () => {
  const theme = useTheme();
  const lang = useSelector(getLang);

  const classes = useStyles({ lang });

  const { t } = useTranslation();
  const { itemsEstimation } = useCart();
  const { renderLanguageField } = useMultiLanguage();

  const orderDiscounts = useSelector(getOrderDiscounts);
  const { promotionsById } = useSelector(promotionsSelectors.promotionsData);

  const knownAddress = useSelector(shoppingFlowSelectors.getKnownAddress);
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const orderDetails = useSelector(getOrderDetails);
  const prepareToPlaceOrder = useSelector(prepareToPlaceOrderSelectors.getPrepareToPlaceOrder);

  const promotionIconName = useCallback<TPromotionIconName>(
    (discountTypeName, orderType, clientOrderType) => {
      switch (discountTypeName) {
        case 'serviceAreaUsageFeesDiscount':
          if (clientOrderType === 'selfPickup' || orderType === 'selfPickup') return 'icon-box';
          return 'icon-delivery';
        case 'itemRefund':
          return 'icon-tag';
        case 'generalDiscount':
          return 'icon-discount';
        case 'itemForFree':
        default:
          return 'icon-gift';
      }
    },
    [],
  );

  const primaryQuantityUnitText = useCallback<TPrimaryQuantityUnitText>(
    (promotion) => {
      if (!promotion?.storeProductSource) return '';

      let primaryQuantityUnit = '';

      if (
        promotion.storeProductSource.soldByWeight &&
        promotion.storeProductSource.product?.primaryQuantityUnit
      ) {
        primaryQuantityUnit = renderLanguageField(
          promotion.storeProductSource.product?.primaryQuantityUnit.multiLang,
          'name',
        );
        return primaryQuantityUnit;
      }

      primaryQuantityUnit = renderLanguageField(
        promotion?.storeProductSource?.productSellingUnits[0].sellingUnit?.multiLang,
        'name',
      );
      return primaryQuantityUnit;
    },
    [renderLanguageField],
  );

  const promotionText = useCallback<TPromotionText>(
    (promotion: IPromotion, labelSuffix = 'promotionsBanner') => {
      const { orderType, minTotalValueBeforePreparation, minTotalValueAfterPreparation } =
        promotion.parameters;

      const promotionOrderType =
        (labelSuffix === 'incentivePromotion' && orderDetails.orderType) || orderType || 'all';

      const discountValue =
        promotion.valueFormulaType.sign === '%'
          ? `${convertNumberToWholeOrTwoDecimal(promotion.valueFormula)}%`
          : `₪${convertNumberToWholeOrTwoDecimal(promotion.valueFormula)}`;

      let sellingUnitName = '';
      let primaryQuantityUnit = '';
      let productPromotedPrice = null;

      if (promotion.storeProductSource) {
        const sellingUnitId = promotion?.storeProductSource?.productSellingUnits[0].sellingUnit.id;
        sellingUnitName = // 2 - weighable unit, 5 - nonWeighable unit
          theme.direction === 'rtl' &&
          (sellingUnitId === 2 || sellingUnitId === 5) &&
          promotion.valueFormulaSourceQuantity === 1
            ? ''
            : renderLanguageField(
                promotion?.storeProductSource?.productSellingUnits[0].sellingUnit?.multiLang,
                'name',
              );
        primaryQuantityUnit = primaryQuantityUnitText(promotion);

        if (promotion.valueFormulaType.sign === '%') {
          productPromotedPrice =
            promotion.storeProductSource.price -
            (promotion.storeProductSource.price * parseFloat(promotion.valueFormula)) / 100;
        } else {
          productPromotedPrice =
            promotion.storeProductSource.price - parseFloat(promotion.valueFormula);
        }
      }
      let translationSuffix;

      switch (promotion.discountType.name) {
        case 'serviceAreaUsageFeesDiscount':
          if (promotion.valueFormula === '100.0%') {
            translationSuffix = '.fullDiscount';
            break;
          }
          translationSuffix = '.partialDiscount';
          break;
        case 'generalDiscount':
          if (
            promotion.promotionType.name === 'forCartEstimationBeforePreparation' ||
            promotion.promotionType.name === 'forCartEstimationAfterPreparation'
          ) {
            if (
              (minTotalValueBeforePreparation && minTotalValueBeforePreparation > 10) ||
              (minTotalValueAfterPreparation && minTotalValueAfterPreparation > 10)
            ) {
              translationSuffix = '.minimumOrder';
              break;
            }
          }

          translationSuffix = '.smallMinimumOrder';
          break;
        default:
          translationSuffix = '';
      }

      return `${t(
        `${labelSuffix}.generatedText.${promotion.promotionType.name}.${promotion.discountType.name}.${promotionOrderType}${translationSuffix}`,
        {
          valueFormulaSourceQuantity:
            promotion.valueFormulaSourceQuantity !== 1 ? promotion.valueFormulaSourceQuantity : '',
          sellingUnitName:
            promotion.valueFormulaSourceQuantity === 1 ? sellingUnitName : sellingUnitName,
          storeProductSourceName: promotion?.storeProductSource?.name,
          minimumOrder: minTotalValueBeforePreparation || minTotalValueAfterPreparation,
          discountValue,
          productPromotedPrice:
            productPromotedPrice !== null ? parseFloat(productPromotedPrice.toFixed(2)) : '', // productPromotedPrice can be zero by design
          primaryQuantityUnit,
          discountShortage: minTotalValueBeforePreparation
            ? (minTotalValueBeforePreparation - itemsEstimation).toFixed(2)
            : 0,
        },
      )}`;
    },
    [
      itemsEstimation,
      orderDetails.orderType,
      primaryQuantityUnitText,
      renderLanguageField,
      t,
      theme.direction,
    ],
  );

  const incentiveToDisplay: IPromotion = useMemo(() => {
    const promotionsForIncentive = Object.values(promotionsById).filter(
      (promotion) =>
        promotion.promotionType.name === 'forCartEstimationBeforePreparation' &&
        !orderDiscounts.find(
          (orderDiscount) => promotion.nonObfuscatedId === orderDiscount.promotion,
        ),
    );

    const currentOrderType =
      !isLoggedIn && orderDetails.orderType === 'delivery' && !knownAddress // on EC default orderType is delivery even when user didnt choose anything
        ? null
        : orderDetails.orderType;

    const relevantPromotions = promotionsForIncentive.filter((promotion) => {
      if ((promotion.parameters.minTotalValueBeforePreparation as number) <= itemsEstimation) {
        return false;
      }

      if (
        isLoggedIn &&
        promotion.discountType.name === 'serviceAreaUsageFeesDiscount' &&
        prepareToPlaceOrder.serviceAreaUsageFees === 0 // we dont want to show delivery/pickup incentive if there is no service fee
      ) {
        return false;
      }

      if (promotion.valueFormulaType.id === 0 && parseFloat(promotion.valueFormula) < 0.1) {
        return false;
      }

      return (
        validateOrderType(promotion, currentOrderType) &&
        validatePromotionRelevantWeekday(
          promotion.parameters.relevantWeekdays,
          orderDetails.preferredDay,
        )
      );
    });

    return relevantPromotions.sort(
      (a, b) =>
        (a.parameters.minTotalValueBeforePreparation as number) -
        (b.parameters.minTotalValueBeforePreparation as number),
    )[0]; // display with smallest minTotalValueBeforePreparation
  }, [
    promotionsById,
    isLoggedIn,
    orderDetails.orderType,
    orderDetails.preferredDay,
    knownAddress,
    orderDiscounts,
    itemsEstimation,
    prepareToPlaceOrder.serviceAreaUsageFees,
  ]);

  const incentivesLabel = useMemo(() => {
    if (!incentiveToDisplay) return;

    return (
      <Box style={{ display: 'flex' }}>
        <Icon
          name={promotionIconName(
            incentiveToDisplay.discountType.name,
            incentiveToDisplay.parameters.orderType,
            orderDetails.orderType,
          )}
          color="secondary"
          style={{ fontSize: '18px' }}
        />
        <Box ml={6 / 8}>
          <Typography className={classNames(classes.promotionProgressLabel, 'font-family-rubik')}>
            {promotionText(incentiveToDisplay, 'incentivePromotion')}
          </Typography>
          {incentiveToDisplay.discountType.name === 'itemRefund' && (
            <TooltipOnIcon
              tooltipTitle={t('incentivePromotion.tooltip.redeemRefund', {
                productName: incentiveToDisplay.storeProductSource?.name,
              })}
              tooltipPlacement="bottom"
              initialClasses={{ tooltip: classes.tooltip, questionIcon: classes.questionIcon }}
            />
          )}
        </Box>
      </Box>
    );
  }, [
    classes.promotionProgressLabel,
    classes.questionIcon,
    classes.tooltip,
    incentiveToDisplay,
    orderDetails.orderType,
    promotionIconName,
    promotionText,
    t,
  ]);

  const incentivesPromotionsProgressBar = useCallback(
    (onClose) => {
      if (!incentiveToDisplay) return;

      return (
        <ProgressBarWithLabel
          onClose={onClose}
          maxValue={incentiveToDisplay.parameters.minTotalValueBeforePreparation as number}
          currentQuantity={itemsEstimation}
          label={incentivesLabel}
        />
      );
    },
    [incentiveToDisplay, itemsEstimation, incentivesLabel],
  );

  return {
    promotionIconName,
    primaryQuantityUnitText,
    promotionText,
    incentivesPromotionsProgressBar,
    incentiveToDisplay,
    incentivesLabel,
  };
};
export default usePromotions;
