import { Box, IconButton, LinearProgress } from '@material-ui/core';
import { useMobile } from 'hooks';
import React, { FC } from 'react';
import Icon from 'ui/common/icons/Icon';
import useStyles from './styles';
import { IProgressBarWithLabel } from './types';

const ProgressBarWithLabel: FC<IProgressBarWithLabel> = ({
  maxValue,
  currentQuantity,
  label,
  onClose,
}) => {
  const { isMobile } = useMobile();

  const classes = useStyles({ isMobile });

  return (
    <Box className={classes.wrapper}>
      <Box>
        <Box style={{ display: 'flex', marginBottom: '8px', justifyContent: 'space-between' }}>
          <Box className={classes.label}>{label}</Box>
          {onClose && (
            <IconButton onClick={onClose} className={classes.closeButton}>
              <Icon name="icon-button-x" classes={{ root: classes.closeIcon }} />
            </IconButton>
          )}
        </Box>
        <LinearProgress
          variant="determinate"
          value={(currentQuantity * 100) / maxValue}
          classes={{ root: classes.progressBarRoot }}
        />
      </Box>
    </Box>
  );
};

export default ProgressBarWithLabel;
