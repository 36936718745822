import React, { FC, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { IContinueToPaymentFixed } from 'components/mobile/ContinueToPaymentFixed/types';
import { Box } from '@material-ui/core';
import { useAutoHideOnKeyboardOpen, useCart } from 'hooks';
import ContinueToPayment from 'ui/mobile/buttons/GoToCart';
import Typography from 'ui/common/Typography';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const ContinueToPaymentFixed: FC<IContinueToPaymentFixed> = ({
  pinToBody,
  onContinueToPayment,
  label,
  hideBasketIcon,
  disabled,
}) => {
  const { t } = useTranslation();
  const { uniqueItemCount, itemsEstimation } = useCart();

  const classes = useStyles();
  const visible = useAutoHideOnKeyboardOpen();

  const continueToPayment = useMemo(() => {
    if (!visible) return null;
    return (
      <Box zIndex={5} className={classes.buttonWrp}>
        <Box>
          <ContinueToPayment
            hideBasketIcon={hideBasketIcon}
            onClick={onContinueToPayment}
            price={itemsEstimation}
            count={uniqueItemCount}
            label={label}
            disabled={disabled}
          />
        </Box>
        <Box className={classes.revaluationNotice}>
          <Typography variant="body1" fontSize={13} color="mediumGrey" align="center">
            {t('topCard.revaluation')}
          </Typography>
        </Box>
      </Box>
    );
  }, [
    classes.buttonWrp,
    classes.revaluationNotice,
    disabled,
    hideBasketIcon,
    itemsEstimation,
    label,
    onContinueToPayment,
    t,
    uniqueItemCount,
    visible,
  ]);

  return pinToBody
    ? ReactDOM.createPortal(continueToPayment, document.getElementsByTagName('body')[0])
    : continueToPayment;
};

export default ContinueToPaymentFixed;
