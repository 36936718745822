import { OrderDraftsService } from 'api/services/OrderDraftsService';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { authSelectors, orderSelectors } from 'store';
import { getOrderDetails, getOrderMode } from 'store/modules/orderDetails/selectors';
import { TUseOrderDrafts } from './types';

const useOrderDrafts: TUseOrderDrafts = (timeForUpdateInMS = 10000) => {
  const orderDetails = useSelector(getOrderDetails);
  const orderItems = useSelector(orderSelectors.orderItems);
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const orderMode = useSelector(getOrderMode);
  const onClientBehalf = useSelector(authSelectors.onClientBehalf);

  const router = useRouter();

  const [activityTime, setActivityTime] = useState<number>(orderDetails.lastActivityTime || 0);

  useEffect(() => {
    let draftsWasPosted = false;

    const updateDrafts = (): void => {
      setActivityTime(orderDetails.lastActivityTime as number);
      OrderDraftsService.postOrderDraft({
        content: JSON.stringify(
          orderItems.map((item) => ({
            ...item,
            storeProduct: {
              id: item.storeProduct.id,
              ancestor: item.storeProduct.ancestor,
            },
          })),
        ),
      }).then((actionResult) => {
        if (!actionResult.success) return;
        draftsWasPosted = true;
      });
    };

    const postNotFinishedDrafts = (): void => {
      if (!draftsWasPosted) {
        updateDrafts();
      }
    };

    if (
      !isLoggedIn ||
      orderMode !== 'new' ||
      onClientBehalf ||
      !orderDetails.lastActivityTime ||
      orderDetails?.lastActivityTime === activityTime
    )
      return;

    const interval = setInterval(updateDrafts, timeForUpdateInMS);

    router.events.on('routeChangeStart', postNotFinishedDrafts);
    return () => {
      clearInterval(interval);
      router.events.off('routeChangeStart', postNotFinishedDrafts);
    };
  }, [
    timeForUpdateInMS,
    orderMode,
    onClientBehalf,
    isLoggedIn,
    orderDetails.lastActivityTime,
    activityTime,
    orderItems,
    router.events,
  ]);
};

export default useOrderDrafts;
